import React, { useState } from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa';

interface DayDetails {
  dayNo: number;
  videoLink: string;
  targetMuscle: string;
}

interface WorkoutPlan {
  name: string;
  length: number;
  avgDuration: number;
  level: string;
  equipment: string;
  days: DayDetails[];
}

function App() {
  const [workoutPlan, setWorkoutPlan] = useState<WorkoutPlan>({
    name: '',
    length: 0,
    avgDuration: 0,
    level: 'Beginner',
    equipment: '',
    days: []
  });

  const [dayDetails, setDayDetails] = useState<DayDetails[]>([
    { dayNo: 1, videoLink: '', targetMuscle: '' }
  ]);

  const [showConfirm, setShowConfirm] = useState<{ visible: boolean, index: number | null }>({ visible: false, index: null });

  const handlePlanChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setWorkoutPlan({ ...workoutPlan, [name]: value });
  };

  const handleDayChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newDayDetails = [...dayDetails];
    newDayDetails[index] = { ...newDayDetails[index], [name]: value };
    setDayDetails(newDayDetails);
  };

  const addDay = () => {
    setDayDetails([...dayDetails, { dayNo: dayDetails.length + 1, videoLink: '', targetMuscle: '' }]);
  };

  const confirmRemoveDay = (index: number) => {
    setShowConfirm({ visible: true, index });
  };

  const removeDay = () => {
    if (showConfirm.index !== null) {
      const newDayDetails = dayDetails.filter((_, i) => i !== showConfirm.index).map((day, i) => ({
        ...day,
        dayNo: i + 1
      }));
      setDayDetails(newDayDetails);
      setShowConfirm({ visible: false, index: null });
    }
  };

  const generateEmail = () => {
    const emailContent = `
Workout Plan Name: ${workoutPlan.name}
Workout Plan Length: ${workoutPlan.length} days
Average Workout Duration: ${workoutPlan.avgDuration} minutes
Workout Level: ${workoutPlan.level}
Equipment: ${workoutPlan.equipment}

Day Details:
${dayDetails.map(day => `
  Day No.: ${day.dayNo}
  YouTube Video Link: ${day.videoLink}
  Target Muscle: ${day.targetMuscle}
`).join('\n')}
    `;
    const mailtoLink = `mailto:support@corporatephantoms.com?subject=Workout Plan&body=${encodeURIComponent(emailContent)}`;
    window.location.href = mailtoLink;
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6 text-center text-blue-600">Workout Plan Generator</h1>
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <div className="mb-4">
          <label className="block mb-2 text-gray-700">Workout Plan Name:</label>
          <input
            type="text"
            name="name"
            value={workoutPlan.name}
            onChange={handlePlanChange}
            placeholder="Enter the name of the workout plan"
            className="border p-2 w-full rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 text-gray-700">Workout Plan Length (days):</label>
          <input
            type="number"
            name="length"
            value={workoutPlan.length}
            onChange={handlePlanChange}
            placeholder="Enter the number of days"
            className="border p-2 w-full rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 text-gray-700">Average Workout Duration (minutes):</label>
          <input
            type="number"
            name="avgDuration"
            value={workoutPlan.avgDuration}
            onChange={handlePlanChange}
            placeholder="Enter the average duration in minutes"
            className="border p-2 w-full rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 text-gray-700">Workout Level:</label>
          <select
            name="level"
            value={workoutPlan.level}
            onChange={handlePlanChange}
            className="border p-2 w-full rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option>All</option>
            <option>Beginner</option>
            <option>Intermediate</option>
            <option>Advanced</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block mb-2 text-gray-700">Equipment:</label>
          <input
            type="text"
            name="equipment"
            value={workoutPlan.equipment}
            onChange={handlePlanChange}
            placeholder="Enter the required equipment"
            className="border p-2 w-full rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
      </div>
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <h2 className="text-2xl font-bold mb-4 text-blue-600">Day Details</h2>
        {dayDetails.map((day, index) => (
          <div key={index} className="mb-4 p-4 border rounded-lg shadow-md bg-gray-50">
            <label className="block mb-2 text-gray-700">Day No.:</label>
            <input
              type="number"
              name="dayNo"
              value={day.dayNo}
              onChange={(e) => handleDayChange(index, e)}
              placeholder="Enter the day number"
              className="border p-2 w-full rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <label className="block mb-2 text-gray-700">YouTube Video Link:</label>
            <input
              type="text"
              name="videoLink"
              value={day.videoLink}
              onChange={(e) => handleDayChange(index, e)}
              placeholder="Enter the YouTube video link"
              className="border p-2 w-full rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <label className="block mb-2 text-gray-700">Video Target Muscle:</label>
            <input
              type="text"
              name="targetMuscle"
              value={day.targetMuscle}
              onChange={(e) => handleDayChange(index, e)}
              placeholder="Enter the target muscle"
              className="border p-2 w-full rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button onClick={() => confirmRemoveDay(index)} className="bg-red-500 text-white p-2 rounded mt-2 hover:bg-red-600 transition duration-300 flex items-center">
              <div className="mr-2"><FaTrash /></div>
              Remove Day
            </button>
          </div>
        ))}
        <button onClick={addDay} className="bg-blue-500 text-white p-2 rounded mt-2 hover:bg-blue-600 transition duration-300 flex items-center">
          <div className="mr-2"><FaPlus /></div>
          Add Day
        </button>
      </div>
      <button onClick={generateEmail} className="bg-yellow-500 text-white p-2 rounded mt-4 hover:bg-yellow-600 transition duration-300">Send Email</button>
      <div className="bg-white shadow-md rounded-lg p-6 mt-6">
        <h2 className="text-2xl font-bold mb-4 text-blue-600">Day Details Table</h2>
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2">Day No.</th>
              <th className="py-2">YouTube Video Link</th>
              <th className="py-2">Target Muscle</th>
            </tr>
          </thead>
          <tbody>
            {dayDetails.map((day, index) => (
              <tr key={index}>
                <td className="border px-4 py-2">{day.dayNo}</td>
                <td className="border px-4 py-2">{day.videoLink}</td>
                <td className="border px-4 py-2">{day.targetMuscle}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showConfirm.visible && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-md">
            <h2 className="text-xl mb-4">Confirm Delete</h2>
            <p>Are you sure you want to delete this day?</p>
            <div className="mt-4 flex justify-end">
              <button onClick={() => setShowConfirm({ visible: false, index: null })} className="bg-gray-500 text-white p-2 rounded mr-2">Cancel</button>
              <button onClick={removeDay} className="bg-red-500 text-white p-2 rounded">Delete</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;